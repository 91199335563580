<template>
  <div class="row">
    <div class="col-12 col-lg-8 mb-4 mb-lg-0">
      <label class="label">{{ type }} Key</label>
      <div v-if="apiKey.key_roll">
        <div>
          <code>{{ apiKey.key_roll }}</code>
        </div>
        <div>
          <span class="text-muted small">Old: {{ apiKey.key }}</span>
          <span class="badge badge-warning ml-2">Expires {{ getDateFromNow(apiKey.expired) }}</span>
        </div>
      </div>
      <div v-else>
        <code>{{ apiKey.key }}</code>
      </div>
    </div>
    <div class="col-12 col-lg-3 mb-4 mb-lg-0">
      <label class="label">Created</label>
      <div>
        {{ getDateFormatted(apiKey.created) }}
      </div>
    </div>
    <div class="col-12 col-lg-1 text-lg-right">
      <a-dropdown :trigger="['click']">
        <a-button size="small">
          <i class="fal fa-ellipsis-h"></i>
        </a-button>
        <a-menu slot="overlay" @click="onMenuClick">
          <a-menu-item v-if="apiKey.key_roll" key="cancel" class="text-danger">
            <i class="fal fa-fw fa-minus-circle"></i> Cancel Roll
          </a-menu-item>
          <a-menu-item v-else key="roll">
            <key-item-roll
              :type="type"
              :visible="visible"
              @cancel="visible = false"
              @success="onSuccess"
            >
              <i class="fal fa-fw fa-exchange"></i> Roll Key&hellip;
            </key-item-roll>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import KeyItemRoll from '@/components/Key/KeyItemRoll'

export default {
  components: {
    KeyItemRoll,
  },

  props: {
    apiKey: Object,
    type: String,
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    getDateFormatted(date) {
      return moment(date).format('LL')
    },
    getDateFromNow(date) {
      return moment(date).fromNow()
    },
    onMenuClick(e) {
      switch (e.key) {
        case 'cancel':
          this.onCancel()
        break

        case 'roll':
          this.visible = true
        break
      }
    },
    async onCancel() {
      try {
        var { data } = await this.$httpd.delete(`/user/key`, {
          data: { type: this.type }
        })

        this.$message.success('API key roll has been cancelled.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }
    },
    onSuccess(data) {
      this.visible = false
      this.$store.commit('user', data)
    },
  },
}
</script>
