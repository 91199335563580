<template>
  <div>
    <slot />
    <a-modal
      :confirmLoading="loading"
      :okText="okText"
      :title="$store.state.livemode ? 'Roll Live Key' : 'Roll Test Key'"
      :visible="visible"
      @cancel="$emit('cancel')"
      @ok="onConfirm"
    >
      Expire {{ type }} key &nbsp;
      <a-select defaultValue="0" style="width: 200px;" :disabled="!$store.state.livemode" @change="onChange">
        <a-select-option value="0">immediately</a-select-option>
        <a-select-option value="1">in 1 hour</a-select-option>
        <a-select-option value="24">in 24 hours</a-select-option>
      </a-select>
      <small>
        <p class="mt-4 mb-0">You can cancel the roll anytime before expiry. After the key expires, you’ll be unable to perform any actions with the old key.</p>
      </small>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    type: String,
  },

  data() {
    return {
      loading: false,
      expiryHours: 0,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$httpd.put(`/user/key`, {
          expiry: this.expiryHours,
          livemode: this.$store.state.livemode,
          type: this.type,
        })

        this.$message.success('API key has been rolled.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onChange(val) {
      this.expiryHours = val
    },
  },

  computed: {
    okText() {
      return `Roll ${ this.type.charAt(0).toUpperCase() + this.type.slice(1) } Key`
    },
  },
}
</script>
