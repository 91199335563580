<template>
  <div>
    <div class="card mb-3">
      <div class="card-header">
        <strong>Manage API Keys</strong>
      </div>
      <div class="card-body">
        You can reset your API keys anytime. This is especially useful if you feel that a key has been compromised.
      </div>
      <KeyItem
        v-for="(apiKey, i) in apiKeys"
        :key="apiKey.key"
        class="card-body"
        :class="{ 'border-bottom': i < apiKeys.length - 1 }"
        :apiKey="apiKey"
        :type="getKeyType(apiKey.key)"
        @success="e => $store.commit('user', e)"
      />
      <div class="card-footer text-muted">
        <small>
          <i class="fas fa-exclamation-triangle text-warning mr-1"></i> Never share the secret key with anyone or use it in your frontend application where it could be spoofed.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import KeyItem from '@/components/Key/KeyItem'

export default {
  components: {
    KeyItem,
  },

  methods: {
    getKeyType(key) {
      if (key && key.startsWith('sk_')) {
        return 'secret'
      } else {
        return 'public'
      }
    },
  },

  computed: {
    apiKeys() {
      if (this.$store.state.livemode) {
        return [this.$store.state.user.keys.public, this.$store.state.user.keys.secret]
      } else {
        return [this.$store.state.user.keys.public_test, this.$store.state.user.keys.secret_test]
      }
    },
  },
}
</script>
